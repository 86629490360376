export const sampleReport = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>10528 KEUKA Drive, Jacksonville, FL 32218 - Property Report</title>
</head>
<body>

<header>
    <div class="container">
        <h1>10528 KEUKA Drive, Jacksonville, FL 32218</h1>
        <p class="last-updated">Last Updated: February 2025</p>
    </div>
</header>

<main class="container">
    <!-- Listing Details -->
    <section>
        <h2>Listing Details</h2>
        <figure class="photo-placeholder">
        <img src="https://photos.zillowstatic.com/fp/2955ee2b340eeae2314190c0a56fd8da-uncropped_scaled_within_1536_1152.webp" alt="10528 KEUKA Drive Jacksonville FL">
        <figcaption>10528 KEUKA Drive Jacksonville FL</figcaption>
    </figure>
        <div class="stat-grid">
            <div class="stat-card">
                <p><strong>Price:</strong> $289,000</p>
                <p><strong>Bedrooms:</strong> 3 <strong>Bathrooms:</strong> 2</p>
                <p><strong>Square Footage:</strong> 1,350 sqft</p>
            </div>
            <div class="stat-card">
                <p><strong>Listing Type:</strong> Single Family Residence</p>
                <p><strong>Year Built:</strong> 1973</p>
                <p><strong>Lot Size:</strong> 0.25 Acres</p>
            </div>
        </div>
    </section>

    <!-- Property Overview -->
    <section>
        <h2 class="start-margin">Property Overview</h2>


        <div class="historic-note">
            <h3>Location Overview</h3>
            <p>
                <a href="https://www.google.com/maps/place/10528+KEUKA+Drive,+Jacksonville,+FL+32218" target="_blank">View on Google Maps</a>
            </p>
            <p>
                Located in Jacksonville’s Northside area, this property sits amidst a region known for its blend of suburban neighborhoods and convenient access to major transportation routes. Though specific historic records for this property are limited, the Northside has seen steady residential development since the mid-20th century, shaping its diverse communities and nearby commercial hubs. For a more detailed look at Jacksonville’s historical background, visit the <a href="https://www.jaxhistory.org/" target="_blank">Jacksonville Historical Society</a>.
            </p>
        </div>
        
        <p>
            Set near I-295 and within an easy drive to both Downtown Jacksonville and the Jacksonville International Airport, this location offers a balanced lifestyle for commuters and those looking to stay close to the city’s amenities. The surrounding area features established neighborhoods, local parks, and proximity to shopping destinations such as the River City Marketplace, making it appealing for families, professionals, and retirees alike.
        </p>
    </section>

    <!-- Location Analysis -->
    <section>
        <h2>Location Analysis</h2>
        <div class="stat-grid">
            <div class="stat-card">
                <h4>Transportation & Commute</h4>
                <ul>
                    <li>Access to I-295: ~2 miles</li>
                    <li>Downtown Jacksonville: ~10 miles</li>
                    <li>Jacksonville International Airport (JAX): ~6 miles</li>
                    <li>Public Transit: <a href="https://www.jtafla.com/" target="_blank">JTA Bus Routes</a></li>
                </ul>
            </div>
            <div class="stat-card">
                <h4>Local Amenities</h4>
                <ul>
                    <li><a href="https://www.visitjacksonville.com/" target="_blank">City of Jacksonville Visitor Info</a></li>
                    <li>River City Marketplace</li>
                    <li>Multiple grocery stores within a 5-mile radius</li>
                    <li>Community parks and recreation centers</li>
                </ul>
            </div>
            <div class="stat-card">
                <h4>Healthcare</h4>
                <ul>
                    <li><a href="https://www.ufhealthjax.org/" target="_blank">UF Health Jacksonville</a></li>
                    <li><a href="https://www.baptistjax.com/locations/baptist-north-medical-campus" target="_blank">Baptist North Medical Campus</a></li>
                    <li>Urgent care clinics nearby</li>
                </ul>
            </div>
        </div>
    </section>

    <!-- Neighborhood Analysis, Demographics, Crime, Community & Public Services -->
    <section>
        <h2>Neighborhood & Community</h2>
        <p>
            The Northside of Jacksonville is recognized for its suburban atmosphere, diversity, and affordability compared to other parts of the city. With a mix of established residential streets and newer developments, residents enjoy relatively short commute times, access to natural attractions like the Timucuan Ecological and Historic Preserve, and the convenience of nearby commercial centers.
        </p>
        <p>
            Demographically, the Northside reflects the broader Jacksonville population with a range of income levels, a mix of families and professionals, and a growing retiree community. Crime levels vary by subdivision, so reviewing up-to-date reports through the <a href="https://transparency.jaxsheriff.org/" target="_blank">Jacksonville Sheriff’s Office</a> is recommended. For more comprehensive demographic data, consult the <a href="https://data.census.gov/cedsci/profile?g=1600000US1235000" target="_blank">U.S. Census Bureau</a>.
        </p>
        <p>
            Public services include well-connected roadways, multiple JTA bus routes, and routine city maintenance. Emergency services are provided by the Jacksonville Sheriff’s Office and the <a href="https://www.coj.net/departments/fire-and-rescue.aspx" target="_blank">Jacksonville Fire and Rescue Department</a>. Nearby community centers host cultural events, recreational activities, and public meetings that foster local engagement.
        </p>
    </section>

    <!-- Community Information -->
    <section>
        <h2>Community Information</h2>
        <figure class="photo-placeholder">
            <img src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F889783013%2F533841638061%2F1%2Foriginal.20241101-181256?crop=focalpoint&fit=crop&auto=format%2Ccompress&q=75&sharp=10&fp-x=0.504734848485&fp-y=0.53625&s=9acd37e7a4bc6e5556fbfbdded00ab0d" alt="Northside Jacksonville Community Events">
            <figcaption>Northside Jacksonville Community Events</figcaption>
        </figure>

        <div class="stat-grid">
            <div class="stat-card">
                <h4>Local Organizations & Groups</h4>
                <ul>
                    <li><a href="https://www.coj.net/" target="_blank">City of Jacksonville</a></li>
                    <li><a href="https://www.neighborhoodcouncil.org/" target="_blank">Neighborhood Associations</a></li>
                    <li><a href="https://www.unitedway.org/local/united-states/florida/united-way-of-northeast-florida" target="_blank">United Way of Northeast Florida</a></li>
                </ul>
            </div>
            <div class="stat-card">
                <h4>Annual Events & Festivals</h4>
                <ul>
                    <li>Jacksonville Fair</li>
                    <li>Various local markets & seasonal festivals</li>
                    <li>River City Pride (citywide)</li>
                </ul>
            </div>
            <div class="stat-card">
                <h4>Local Politics & Governance</h4>
                <ul>
                    <li><a href="https://www.coj.net/city-council.aspx" target="_blank">Jacksonville City Council</a></li>
                    <li><a href="https://www.coj.net/mayor" target="_blank">Office of the Mayor</a></li>
                    <li><a href="https://www.duvalelections.com/" target="_blank">Supervisor of Elections - Duval County</a></li>
                </ul>
            </div>
        </div>
    </section>

    <!-- Infrastructure Details -->
    <section>
        <h2>Infrastructure Details</h2>
        <div class="stat-grid">
            <div class="stat-card">
                <h4>Internet Service</h4>
                <ul>
                    <li><a href="https://www.att.com/" target="_blank">AT&T Fiber</a></li>
                    <li><a href="https://www.xfinity.com/" target="_blank">Xfinity</a></li>
                    <li>5G mobile coverage available from major carriers</li>
                </ul>
            </div>
            <div class="stat-card">
                <h4>Utilities & Power Grid</h4>
                <ul>
                    <li>Electricity & Water: <a href="https://www.jaxenergy.com/" target="_blank">JEA</a></li>
                    <li>Gas: <a href="https://www.peoplesgas.com/" target="_blank">Peoples Gas</a></li>
                    <li>Regular grid maintenance with minimal outage frequency</li>
                </ul>
            </div>
            <div class="stat-card">
                <h4>Municipal & Road Services</h4>
                <ul>
                    <li><a href="https://www.coj.net/departments/public-works" target="_blank">Jacksonville Public Works</a></li>
                    <li>Weekly trash and recycling pickup</li>
                    <li>Periodic bulk waste collection</li>
                </ul>
            </div>
        </div>
    </section>

    <!-- School District Information -->
    <section>
        <h2>School District Information</h2>
        <figure class="photo-placeholder">
            <img src="https://cmg-cmg-tv-10050-prod.cdn.arcpublishing.com/resizer/v2/https%3A%2F%2Fcloudfront-us-east-1.images.arcpublishing.com%2Fcmg%2FA4E5SK25RFFTJPPDDJ3T267JMI.jpg?auth=8d42405afaddc8e489c2a56e406efdb46b7c9e6bcdf5877c26b06b33a97c2c5f&width=1200&height=630&smart=true" alt="Local Duval County Public School">
            <figcaption>Local Duval County Public School</figcaption>
        </figure>
        <p>
            The property falls under Duval County Public Schools, known for a range of specialized academies and magnet programs. The district offers comprehensive academic and extracurricular opportunities, with a focus on STEAM (Science, Technology, Engineering, Arts, and Mathematics) initiatives and community partnerships. For detailed district-wide performance statistics and parent reviews, visit <a href="https://dcps.duvalschools.org/" target="_blank">Duval County Public Schools</a>.
        </p>

        <h3>Public Schools</h3>
        <div class="stat-grid">
            <div class="stat-card">
                <div class="school-header">
                    <h4>Garden City Elementary School</h4>
                </div>
                <p>Grades K-5 | <a href="https://www.niche.com/k12/garden-city-elementary-school-jacksonville-fl/" target="_blank">Niche Profile</a></p>
            </div>
            <div class="stat-card">
                <div class="school-header">
                    <h4>Highlands Middle School</h4>
                </div>
                <p>Grades 6-8 | <a href="https://www.niche.com/k12/highlands-middle-school-jacksonville-fl/" target="_blank">Niche Profile</a></p>
            </div>
            <div class="stat-card">
                <div class="school-header">
                    <h4>First Coast High School</h4>
                </div>
                <p>Grades 9-12 | <a href="https://www.niche.com/k12/first-coast-high-school-jacksonville-fl/" target="_blank">Niche Profile</a></p>
            </div>
        </div>

        <h3>Nearby Private Schools</h3>
        <div class="stat-grid">
            <div class="stat-card">
                <div class="school-header">
                    <h4>Victory Christian Academy</h4>
                </div>
                <p><a href="https://www.niche.com/k12/victory-christian-academy-jacksonville-fl/" target="_blank">Niche Profile</a></p>
            </div>
            <div class="stat-card">
                <div class="school-header">
                    <h4>Faith Baptist Academy</h4>
                </div>
                <p><a href="https://www.niche.com/k12/faith-baptist-academy-jacksonville-fl/" target="_blank">Niche Profile</a></p>
            </div>
        </div>
    </section>

    <!-- Environmental Assessment -->
    <section>
        <h2>Environmental Assessment</h2>
        <div class="warning-box">
            <h3>Environmental Considerations</h3>
            <div class="stat-grid">
                <div class="stat-card">
                    <h4>Flood Zone Information</h4>
                    <p>Verify property status at <a href="https://msc.fema.gov/portal/home" target="_blank">FEMA Flood Maps</a></p>
                </div>
                <div class="stat-card">
                    <h4>Hurricane Preparedness</h4>
                    <p>For local storm plans, visit <a href="https://www.coj.net/departments/fire-and-rescue/emergency-preparedness.aspx" target="_blank">Jacksonville Emergency Preparedness</a></p>
                </div>
            </div>
            <h4>Additional Environmental Factors:</h4>
            <ul>
                <li>Hurricane Zone: Florida building codes for wind and storm protection apply</li>
                <li>Wetland Proximity: Some areas of North Jacksonville include protected marshland</li>
                <li>Air Quality: Generally good, with occasional mild impacts from seasonal conditions</li>
                <li>Radon Levels: Check local measurements through <a href="https://floridadep.gov/" target="_blank">Florida DEP</a></li>
            </ul>
        </div>
    </section>
</main>

<footer>
    <div class="container">
        <p>Note: All information should be independently verified through official sources. Property may be subject to additional regulations, HOA guidelines, and environmental considerations.</p>
    </div>
</footer>

</body>
</html>

`;
