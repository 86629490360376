import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Typography,
  Alert,
  CircularProgress,
  Paper,
  Autocomplete,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  AppBar,
  Toolbar,
  Fade,
  LinearProgress,
} from "@mui/material";
import { track } from "@vercel/analytics";
import { Search } from "lucide-react";
import { sampleReport } from "./sampleReport";
import "./styles.css";
const loadingMessages = [
  "Sending request",
  "Finding address",
  "Processing request",
  "Locating documents",
  "Writing up profile",
  "Integrating analysis",
  "Verifying information",
  "Formatting profile",
  "Linking sources",
  "Double checking",
  "Cleaning things up",
  "Making things pretty",
  "Finishing up",
  "Final touches",
];

const PropertyReport = () => {
  const [address, setAddress] = useState(
    "78 John Miller Way, Kearny, NJ 07032"
  );
  const [report, setReport] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [messageVisible, setMessageVisible] = useState(true);
  const [reportVisible, setReportVisible] = useState(true);
  const [progress, setProgress] = useState(0);
  const [hasGeneratedReport, setHasGeneratedReport] = useState(false);
  const [requestStartTime, setRequestStartTime] = useState(null);

  useEffect(() => {
    if (window.google) {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      setPlacesService(
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        )
      );
    }
  }, []);

  useEffect(() => {
    let messageInterval;
    if (loading) {
      setProgress((1 / (loadingMessages.length - 1)) * 100);
      messageInterval = setInterval(() => {
        setMessageVisible(false);
        setTimeout(() => {
          setLoadingMessageIndex((prev) => {
            const newIndex = prev === loadingMessages.length - 1 ? 0 : prev + 1;
            setProgress(((1 + newIndex) / (loadingMessages.length - 1)) * 100);
            return newIndex;
          });
          setMessageVisible(true);
        }, 500);
      }, 3000);
    } else {
      setProgress(100);
      setLoadingMessageIndex(0);
      setMessageVisible(true);
    }
    return () => clearInterval(messageInterval);
  }, [loading]);

  const handleAddressChange = async (event, newValue) => {
    if (!newValue) {
      setAddress("");
      return;
    }

    if (typeof newValue === "string") {
      setAddress(newValue.slice(0, 500));
      fetchPredictions(newValue);
    } else {
      setAddress(newValue.description.slice(0, 500));
      getPlaceDetails(newValue.place_id);
    }
  };

  const fetchPredictions = useMemo(
    () => async (input) => {
      if (!input || !autocompleteService) return;

      try {
        const response = await autocompleteService.getPlacePredictions({
          input,
          types: ["address"],
          componentRestrictions: { country: "us" },
        });
        setPredictions(response.predictions || []);
      } catch (error) {
        console.error("Error fetching predictions:", error);
      }
    },
    [autocompleteService]
  );

  const getPlaceDetails = (placeId) => {
    if (!placesService) return;

    placesService.getDetails(
      { placeId, fields: ["formatted_address"] },
      (place, status) => {
        if (status === "OK") {
          setAddress(place.formatted_address.slice(0, 500));
        }
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setReportVisible(false);

    // Track when user makes a request
    track("property_search_started", {
      address: address,
    });

    // Set request start time
    const startTime = Date.now();
    setRequestStartTime(startTime);

    try {
      // Parse address into components
      // Assuming address is in format: "street, town, state zipcode"
      const addressParts = address.split(",").map((part) => part.trim());
      const street = addressParts[0].replace(/ /g, "-");
      const town = addressParts[1].replace(/ /g, "-");

      // Split last part into state and zipcode
      const stateZip = addressParts[2].trim().split(" ");
      const state = stateZip[0];
      const zipcode = stateZip[1];

      // Construct the URL with query parameters
      const baseUrl =
        "https://homepilot-external-lb.com/property-lookup-extended";
      const queryParams = new URLSearchParams({
        street,
        town,
        state,
        zipcode,
      });

      const url = `${baseUrl}?${queryParams.toString()}`;
      console.log(url);
      const response = await fetch(url, {
        method: "GET", // Changed to GET since the new API uses query parameters
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error(
            "Rate limit exceeded. Please wait before trying again."
          );
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.text(); //await response.json();
      console.log("Zach " + data);

      setTimeout(() => {
        setReport(data); // Adjust this based on the new API's response structure
        setReportVisible(true);
        setHasGeneratedReport(true);

        // Calculate and track request duration
        const endTime = Date.now();
        const duration = endTime - startTime;
        track("property_search_completed", {
          address: address,
          duration_ms: duration,
          success: true,
        });
      }, 500);
    } catch (err) {
      setError("Failed to generate profile. Please try again.");
      console.error("Error:", err);
      setReportVisible(true);

      // Track failed requests
      const endTime = Date.now();
      const duration = endTime - startTime;
      track("property_search_failed", {
        address: address,
        duration_ms: duration,
        error_message: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        p: 0,
        m: 0,
      }}
    >
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            color="primary"
            sx={{
              flexGrow: 1,
              lineHeight: "64px",
              display: "flex",
              alignItems: "center",
              m: 0,
              p: 0,
            }}
          >
            <Box
              component="img"
              src="/logodarkblue.png"
              sx={{
                height: "32px", // Adjust this value to match your desired height
                width: "auto", // This maintains aspect ratio
                marginRight: 1, // Adds some space between the logo and text
                marginBottom: 0.0,
              }}
            />
            HomeProfile
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          position: "relative",
          minHeight: "600px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            },
          }}
        >
          <Box
            component="img"
            src="https://images.squarespace-cdn.com/content/v1/5fbddaca2e34347c7030fecf/1690770104782-POBFSE4AQJ6SI4HGFSQS/image-asset.jpeg"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>

        <Container
          maxWidth="md"
          sx={{
            position: "relative",
            mt: 3,
            borderRadius: 4,
            p: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{
                color: "white",
                mb: 14,
                width: "100%",
                fontWeight: "bold",
              }}
            >
              Find your next move.
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                width: "100%",
                maxWidth: "600px",
              }}
            >
              <Autocomplete
                fullWidth
                freeSolo
                value={address}
                onChange={handleAddressChange}
                onInputChange={(event, newInputValue) => {
                  setAddress(newInputValue.slice(0, 500));
                  fetchPredictions(newInputValue);
                }}
                options={predictions}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Enter property address"
                    required
                    disabled={loading}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        borderRadius: 2,
                        boxShadow: 3,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            type="submit"
                            disabled={loading || !address}
                            edge="end"
                          >
                            {loading ? (
                              <CircularProgress size={24} sx={{ mr: 4 }} />
                            ) : (
                              <Search />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                disabled={loading}
              />
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(255, 255, 255, 0.8)",
                  mt: 2,
                }}
              >
                Making a profile may take up to 45 seconds
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="md" sx={{ py: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 0,
          }}
        >
          <Typography variant="h4" sx={{ mb: 0 }}>
            {hasGeneratedReport ? (
              loading ? (
                <>
                  Loading profile for <br /> {address}
                </>
              ) : (
                "Profile ready"
              )
            ) : loading ? (
              <>
                Loading profile for <br /> {address}
              </>
            ) : (
              "Example profile"
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 1,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Fade in={messageVisible} timeout={500}>
              <Typography
                variant="h6"
                sx={{
                  color: "text.secondary",
                  minHeight: "32px",
                }}
              >
                {loading
                  ? loadingMessages[loadingMessageIndex]
                  : hasGeneratedReport
                  ? address
                  : "1708 S Union Ave, Los Angeles, CA 90015"}
              </Typography>
            </Fade>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                mt: 1,
                mb: 2,
                height: 4,
                borderRadius: 2,
                backgroundColor: "#e0e0e0",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#2196f3",
                  transition: "transform 3s linear",
                },
              }}
            />
          </Box>
        </Box>

        <Box position="relative">
          <Paper
            elevation={0}
            sx={{
              p: 3,
              borderRadius: 4,
              position: "relative",
              border: "1px solid #ddd",
              transition: "all 0.3s ease-in-out",
              filter: loading ? "grayscale(80%) blur(1px)" : "none",
              opacity: loading ? 0.7 : 1,
            }}
          >
            <div
              className="report-content"
              dangerouslySetInnerHTML={{ __html: report || sampleReport }}
            />
          </Paper>

          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "flex-start",
                paddingTop: "24px",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: 4,
              }}
            >
              <CircularProgress size={60} />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default PropertyReport;
